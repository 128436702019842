<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافه جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/admins')"
        />
      </template>
    </Toolbar>
    <div class="form-card">
      <div class="mb-3">
        <label for="userName" class="form-label">
          اسم المستخدم
        </label>
        <input
          type="text"
          class="form-control"
          id="userName"
          v-model="body.userName"
        />
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">
          البريد الالكتروني
        </label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model="body.email"
        />
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">
          كلمه المرور
        </label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="body.password"
        />
      </div>
      <div class="mb-3">
        <label for="phone" class="form-label">
          الهاتف
        </label>
        <input
          type="tel"
          class="form-control"
          id="phone"
          v-model="body.phone"
        />
        <!-- <vue-tel-input
          v-model="body.phone"
          placeholder=""
          v-bind="{
            mode: 'international',
          }"
        ></vue-tel-input> -->
      </div>
      <div class="mb-3">
        <label for="note" class="form-label">
          ملاحظات
        </label>
        <b-form-textarea
          id="textarea-small"
          name="note"
          v-model="body.note"
          placeholder=""
        ></b-form-textarea>
      </div>

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-pencil"
          class="p-ml-2 p-button-info"
          @click="update()"
          v-if="id"
        />
        <Button
          v-else
          label="اضافه جديد"
          icon="pi pi-plus"
          class="p-ml-2 p-button-success"
          @click="save()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        userName: null,
        email: null,
        password: null,
        phone: null,
        userNotes: null,
      },
      id: null,
    };
  },
  methods: {
    save() {
      if (this.body.userName && this.body.email && this.body.password) {
        this.$http.post(`users`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافه بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/admins');
          },
          (err) => {
             this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
    update() {
      if (this.body.userName && this.body.email ) {
        this.$http.put(`users/${this.id}`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/admins');
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`users/${this.id}`).then(
        (res) => {
          this.body = res.data;
          this.body.password=null;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>

<style></style>
